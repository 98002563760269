.home .welcome-area {
  background: linear-gradient(to right, #1e2b28, #1e2926, #1a1a1b, #1a1a1b);
  overflow: hidden;
}
.home .welcome-area .rewardBanner {
  position: absolute;
  right: 0px;
  bottom: -5px;
  height: 100%;
}
.home .welcome-area h1 {
  color: white;
  font-size: 29px;
  font-family: var(--font-reg);
  line-height: 31.9px;
  word-wrap: break-word;
}
.home .welcome-area h2 {
  color: var(--paragraphColor);
  font-size: 14px;
  font-style: normal;
  font-family: var(--font-med);
  line-height: 130%;
}
.home .welcome-area p {
  color: #5df9c2;
  font-size: 45px;
  font-family: var(--font-bold);
  line-height: 45px;
  word-wrap: break-word;
}
.home .welcome-area .buttons-wrapper button {
  color: var(--primaryColor);
  font-family: var(--font-bold);
  font-size: 14px;
  line-height: 135%;
  border-radius: 8px;
  border: 1px solid transparent;
  background: linear-gradient(137deg, #5df9c2 0%, #3ac192 100%);
}
.home .welcome-area .buttons-wrapper button:last-child {
  color: var(--headingColor);
  background: transparent;
  border: 1px solid #5df9c2;
}

.home .splide__pagination__page {
  background: #78797e;
  width: 10px;
  height: 10px;
  opacity: 1;
  margin: 3px 6px;
}
.home .splide__pagination__page.is-active {
  border-radius: 99px;
  width: 24px;
  background: linear-gradient(137deg, #5df9c2 0%, #3ac192 100%);
}

.home .SliderAreaFirst .splide__pagination {
  bottom: -2.2em;
}
.home .SliderAreaFirst li > img {
  width: 100%;
}
.home .CardCustom .presentation {
  background-color: #212632a5;
  backdrop-filter: blur(42px);
}
.home .CardCustom > img {
  transform: translateY(10px);
}
.home .CardCustom .presentation h1 {
  color: var(--headingColor);
  font-size: 16px;
  font-family: var(--font-med);
  font-style: normal;
  line-height: 135%; /* 21.6px */
}
.home .CardCustom .presentation p {
  color: var(--paragraphColor);
  font-family: var(--font-med);
  font-size: 14px;
  font-style: normal;
  line-height: 135%; /* 18.9px */
}
.home .CardCustom .presentation a {
  font-family: var(--font-bold);
  background: linear-gradient(137deg, #5df9c2 0%, #3ac192 100%);
}

.home .RecentWin .top h1 {
  color: var(--headingColor);
  font-family: var(--font-med);
  font-size: 23px;
  font-style: normal;
  line-height: 26.45px;
}
.home .RecentWin .top a {
  font-family: var(--font-med);
  font-size: 16px;
  font-style: normal;
  line-height: 120%;
  background: linear-gradient(137deg, #5df9c2 0%, #3ac192 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home .slider-wrapper-recent .card {
  /* width: 139px; */
  width: 100%;
}
.home .slider-wrapper-recent .card > img {
  width: 100%;
}
.home .slider-wrapper-recent .card > .presentation {
  background: var(--secondaryColor);
}
.home .slider-wrapper-recent .card > .presentation h1 {
  color: var(--headingColor);
  font-family: var(--font-med);
  font-size: 12px;
  font-style: normal;
  line-height: 135%; /* 16.2px */
}
.home .slider-wrapper-recent .card > .presentation button {
  padding: 4px 11px;
  font-family: var(--font-reg);
  font-size: 12px;
  font-style: normal;
  line-height: 135%; /* 16.2px */
  color: #5df9c2;
  border-radius: 8px;
  border: 1px solid #5df9c2;
}
.home .TopRatesGame .slider-wrapper-recent .card {
  /* width: 179px; */
  width: 100%;
}
.home .TopRatesGame .slider-wrapper-recent .card .presentation h1 {
  color: var(--paragraphColor);
}
.home .TopRatesGame .slider-wrapper-recent .card .presentation button {
  width: 100%;
  color: var(--primaryColor) !important;

  background: linear-gradient(137deg, #5df9c2 0%, #3ac192 100%);
}

/* LatestBets */
.home .LatestBets .tabs-area ul li p {
  font-size: 12px;
}

.home .LatestBets .table-area th {
  color: var(--headingColor);
  font-family: var(--font-med);
  font-size: 16px;
  font-style: normal;
  line-height: 125%;
  height: 44px;
  border: 1px solid var(--borderColor);
  border-right: none;
  border-left: none;
  text-align: left;
  padding-left: 1rem;
}
.home .LatestBets .table-area th:first-child,
.home .LatestBets .table-area tbody td:first-child {
  border-left: 1px solid var(--borderColor);
}
.home .LatestBets .table-area th:last-child,
.home .LatestBets .table-area tbody td:last-child {
  border-right: 1px solid var(--borderColor);
  text-align: right;
  padding-right: 2rem;
}
.home .LatestBets .table-area tbody td {
  padding-left: 1rem;
  color: #d0d1d2;
  font-family: var(--font-med);
  font-size: 16px;
  font-style: normal;
  line-height: 125%; /* 20px */
  height: 52px;
  border: 1px solid var(--borderColor);
  border-right: none;
  border-left: none;
}
.home .LatestBets .table-area tbody tr:hover td {
  background-color: var(--secondaryColor);
}

/* RegisterBanner */
.home .RegisterBanner {
  background-image: url("../img/bg-register.png");
  background-position: center;
  background-size: cover;
}
.home .RegisterBanner h1 {
  width: 365px;
  color: var(--headingColor);
  font-family: var(--font-bold);
  font-size: 27px;
  font-style: normal;
  line-height: 110%; /* 29.7px */
}
.home .RegisterBanner h1 span {
  color: #5df9c2;
}
.home .RegisterBanner p {
  color: var(--headingColor);
  font-family: var(--font-reg);
  font-size: 16px;
  font-style: normal;
  line-height: 120%; /* 19.2px */
}
.home .RegisterBanner a {
  background: linear-gradient(137deg, #5df9c2 0%, #3ac192 100%);
  color: var(--primaryColor);
  font-family: var(--font-bold);
  font-size: 14px;
  font-style: normal;
  line-height: 135%;
  width: 136px;
}

/* .home .body-right-area */
.home .body-right-area {
  min-width: 255px;
  background-color: var(--secondaryColor);
  border: 1px solid var(--borderColor);
  position: sticky;
  top: 87px;
}
.home .body-right-area .top-area-first-bar {
  border-bottom: 1px solid var(--borderColor);
}
.home .body-right-area .top-area-first-bar h1 {
  color: var(--headingColor);
  font-family: var(--font-reg);
  font-size: 14px;
  font-style: normal;
  line-height: 130%; /* 18.2px */
}
.home .body-right-area .top-area-first-bar p {
  color: var(--paragraphColor);
  font-family: var(--font-reg);
  font-size: 12px;
  font-style: normal;
  line-height: 135%; /* 16.2px */
  right: 45px;
}
.home .body-right-area .top-area-first-bar > div::after {
  width: 5.56px;
  height: 5.56px;
  background-color: #44ec17;
  border: 1px solid #000000;
  border-radius: 50%;
  position: absolute;
  content: "";
  right: 23px;
  z-index: 3;
  bottom: 15px;
}
.home .body-right-area .top-area-first-bar > div > *:first-child {
  transform: translateX(20px);
  z-index: 3;
}
.home .body-right-area .top-area-first-bar > div > *:nth-child(2) {
  transform: translateX(10px);
  z-index: 2;
}
.home .body-right-area .notification-box {
  background-color: #252525;
}
.home .body-right-area .notification-box .top h1 {
  color: #959699;
  font-size: 12px;
  font-family: var(--font-reg);
  line-height: 16.2px;
  word-wrap: break-word;
}
.home .body-right-area .notification-box .top span {
  background: linear-gradient(144deg, #9BE402 -47.44%, #1FA83C 140.74%);
  border-radius: 9999px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.home .body-right-area .notification-box > h1 {
  color: white;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.home .body-right-area .notification-box > p {
  color: #959699;
  font-size: 12px;
  font-family: var(--font-reg);
  line-height: 16.2px;
  word-wrap: break-word;
}
.home .body-right-area .notification-box button {
  padding-left: 24px;
  padding-right: 24px;
  height: 32px;
  background: #3C502E;
  border-radius: 8px;
  border: 1px #6FCF17 solid;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6FCF17;
  outline: none;
  font-size: 14px;
  font-family: var(--font-med);

  word-wrap: break-word;
}
.notify-top-custom > h1 {
  color: white;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.notify-top-custom button {
  color: #aeb2b1;
  height: 32px;
  font-size: 12px;
  font-family: var(--font-reg);
  line-height: 16.2px;
  word-wrap: break-word;
  border-radius: 8px;
  border: 1px #3c403f solid;
}
.notify-top-custom button.active {
  background: #252525;
  color: white;
  border-color: transparent;
  font-size: 12px;
  font-family: var(--font-reg);
  line-height: 16.2px;
  word-wrap: break-word;
  border-bottom: 1px #6FCF17 solid;
}

.notify-two .no-data p {
  color: #959699;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.home .body-right-area .notification-box .download-wrapper p {
  color: #53ebb7;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
/* comment box */
.CommentCard {
  border: 1px solid var(--borderColor);
}
.CommentCard.active {
  background-color: #252525;
  border-bottom-color: #46d5a2;
}
.CommentCard .top-area h1 {
  color: var(--headingColor);
  font-family: var(--font-reg);
  font-size: 12px;
  font-style: normal;
  line-height: 135%; /* 16.2px */
}
.CommentCard .top-area p {
  color: var(--paragraphColor);
  font-family: var(--font-reg);
  font-size: 8px;
  font-style: normal;
  line-height: 140%;
}

.CommentCard .right-area > p {
  color: var(--paragraphColor);
  font-family: var(--font-reg);
  font-size: 12px;
  font-style: normal;
  line-height: 130%; /* 15.6px */
}
.comment-area {
  background-color: var(--secondaryColor);
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
}
.comment-area input {
  border: none;
  outline: none;
  background: transparent;
  color: var(--headingColor);
  font-family: var(--font-reg);
  font-size: 12px;
  font-style: normal;
  line-height: 130%;
}
.comment-area input::placeholder {
  color: var(--paragraphColor);
}
.message-area {
  background-color: #252525;
  position: absolute;
  bottom: 2%;
  width: 96%;
  left: 2%;
}
.message-area input {
  border: none;
  outline: none;
  background: transparent;
  color: var(--headingColor);
  font-family: var(--font-reg);
  font-size: 12px;
  font-style: normal;
  line-height: 130%;
}
.message-area input::placeholder {
  color: var(--paragraphColor);
}
.home .scroll-area-start {
  overflow: auto;
}
.home .scroll-area-start::-webkit-scrollbar {
  display: none;
}

.LandingFooter {
  padding: 120px 0px 140px 0px;
  background: url("../../assets/img/dashboard/footer-bg.png");
  background-size: cover;
}
.LandingFooter .landing-footer-top {
  margin-bottom: 60px;
}
.LandingFooter .landing-footer-top p {
  color: #b7b8bc;
  font-size: 18px;
  font-family: var(--font-arabic-med);
  line-height: 21.6px;
  word-wrap: break-word;
}
.LandingFooter .landing-footer-top h1 {
  color: white;
  font-size: 23px;
  font-family: "Arabic Pro Medium";
  line-height: 26.45px;
  word-wrap: break-word;
}
.LandingFooter .landing-footer-grid-area {
  margin-bottom: 60px;
}
.LandingFooter .landing-footer-grid-area .head {
  color: white;
  font-size: 23px;
  font-family: "Arabic Pro Medium";
  line-height: 26.45px;
  word-wrap: break-word;
}
.LandingFooter .landing-footer-grid-area li a {
  color: #9a9ba1;
  font-size: 18px;
  font-family: "Arabic Pro Medium";
  line-height: 21.6px;
  word-wrap: break-word;
  text-decoration: none;
}

.LandingFooter .copyright-area h1 {
  color: #b7b8bc;
  font-size: 18px;
  font-family: "Arabic Pro Medium";
  line-height: 21.6px;
  word-wrap: break-word;
}
.LandingFooter .copyright-area li {
  list-style: none;
}
.LandingFooter .copyright-area nav > * + * {
  margin-left: 2rem;
}
.LandingFooter .copyright-area a {
  color: #b7b8bc;
  font-size: 18px;
  font-family: "Arabic Pro Medium";
  line-height: 21.6px;
  word-wrap: break-word;
}
.LandingFooter .copyright-area nav li {
  position: relative;
}
.LandingFooter .copyright-area nav li:first-child::after {
  display: none;
}
.LandingFooter .copyright-area nav li::after {
  width: 8.5px;
  height: 8.5px;
  background-color: #b7b8bc;
  border-radius: 50%;
  position: absolute;
  left: -20px;
  top: 6px;
  content: "";
}
.PaymentProviders .blur2 {
  left: -100px;
  top: -200px;
  transform: translateX(0%);
  z-index: 1;
}

#partners .splide.is-initialized {
  padding: 0px;
}
.common-width-landing {
  width: 90%;
  margin: 0rem auto;
}
@media screen and (max-width: 1250px) {
  .home {
    display: block;
  }
  .home aside {
    position: fixed;
    z-index: 99999;
    left: -100%;
    transition: 0.3s ease all;
  }
  .home aside.active {
    left: 0%;
  }
  .home aside + div .body-area {
    display: block;
  }
  .home aside + div .body-area .body-left-area {
    width: 100% !important;
    margin-bottom: 2rem;
  }
  .home .scroll-area-start {
    height: unset !important;
  }
  footer .why-area {
    grid-template-columns: 1fr;
    grid-gap: 3rem;
  }
  footer .logo-footer-area {
    grid-template-columns: 1fr;
    grid-gap: 3rem;
  }
  footer .cols-footer-area-mbl {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 1250px) {
  footer .list-area-footer {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 3rem;
  }

  footer .copyright-area {
    flex-direction: column;
    gap: 20px;
  }

  footer .submit {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .LatestBetsMobile .table-area {
    overflow: auto;
  }
  .LatestBetsMobile .table-area table {
    min-width: 800px;
  }
  .home .welcome-area .rewardBanner {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .inner-work-sign-in {
    display: block;
    width: 100% !important;
    height: 100vh !important;
  }
  .inner-work-sign-in > *:first-child {
    display: none;
  }
  .inner-work-sign-in > *:last-child {
    width: 100%;
    height: 100vh;
  }
}
@media screen and (max-width: 800px) {
  .popupWrapper .DepositePopUp {
    height: 100vh !important;
    width: 100% !important;
  }
  .home .settings-content-area {
    flex-direction: column;
  }
  .home .settings-content-area > *:last-child {
    width: 100%;
  }
  .settings-content-area::after {
    display: none;
  }
  .settings-options-area {
    width: unset !important;
    align-items: center;
    flex-direction: row;
    overflow: auto;
  }
  .settings-options-area a {
    justify-content: center;
    min-width: 150px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .home .settings-content-area {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .DepositePopUp .qr-container {
    flex-direction: column;
    gap: 20px;
  }
  .popupWrapper .DepositePopUp {
    padding-left: 10px;
    padding-right: 10px;
  }
  .DepositePopUp .qr-container .presentation > h1 {
    text-align: center;
  }
  .DepositePopUp .qr-container .presentation .copy-area {
    flex-direction: column;
    padding: 1rem 1rem;
    height: unset !important;
  }
  .DepositePopUp .qr-container .presentation .copy-area button {
    margin-top: 1rem;
  }

  .user-profile {
    width: 100% !important;
    height: 100vh !important;
  }
}
@media screen and (max-width: 500px) {
  .card-custom-wrapper {
    grid-template-columns: 1fr;
  }
  .home .welcome-area h1 {
    font-size: 22px;
  }
  .home .welcome-area p {
    font-size: 30px;
  }

  .logo-header-img {
    display: block;
    width: 120px;
  }
  header .tabs-area {
    display: none;
  }

  header .burger-icon {
    display: block;
  }
  header .not_login button:nth-child(1) {
    margin-left: 0px;
  }
  header .not_login button:nth-child(2) {
    margin-right: 10px;
  }
  header .not_login button:nth-child(3) {
    display: none;
  }
  .home aside + div .body-area {
    padding-left: 10px;
    padding-right: 10px;
  }
  .home .welcome-area .buttons-wrapper {
    display: flex;
    align-items: center;
  }
  .home .welcome-area .buttons-wrapper button {
    flex: 1;
    width: 100%;
  }
  .home .RegisterBanner h1 {
    width: 100%;
    font-size: 20px;
    line-height: unset;
  }
  .home .RecentWin .top h1 {
    font-size: 20px;
  }

  header {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  header .icon-buttons > button {
    margin-right: 5px;
  }
  header .select-bar {
    display: none;
  }
  header .deposit-button {
    margin-right: 10px;
    margin-left: 10px;
  }
  .home .settings-content-area > *:last-child {
    padding-left: 10px;
    padding-right: 10px;
  }
  .wallet-content-body-area .warning p {
    width: 100% !important;
  }
  .DepositePopUp .warning {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .wallet-content-body-area .option-three .upload-box button {
    height: 50px;
  }
  .DepositePopUp .buttons-wrapper-binance {
    grid-template-columns: 1fr 1fr;
  }
  .user-profile .metal-box .medal-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .user-profile .metal-box .boxes-wrapper {
    grid-template-columns: 1fr;
  }
  .user-profile .heading-medal-grid-mbl {
    grid-template-columns: 1fr 1fr;
  }
  .user-profile .top-px-mbl {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .settings-content-area .privacy-content > p {
    width: 100% !important;
  }
  .settings-content-area .settings-content-body-area-grid-settings {
    grid-template-columns: 1fr;
  }
  .settings-content-area .form-card-mail:first-child::after {
    display: none;
  }

  .settings-content-body-area > table tbody td,
  .settings-content-body-area > table thead th {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 1200px) {
  .LandingFooter .landing-footer-grid-area {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .LandingFooter .landing-footer-top {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
}
@media screen and (max-width: 900px) {
  .LandingFooter .landing-footer-top > * + * {
    margin-top: 3rem;
  }
  .LandingFooter .copyright-area {
    flex-direction: column;
  }
  .LandingFooter .copyright-area > * + * {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 600px) {
  .LandingFooter .copyright-area a {
    font-size: 12px;
  }
  .LandingFooter .copyright-area > * + * {
    justify-content: space-between;
    width: 100%;
  }
  .LandingFooter .copyright-area nav li::after {
    display: none;
  }
  .LandingFooter {
    padding: 60px 0px 80px 0px;
  }
}

.world-map figure {
  background-color: transparent !important;
  display: flex;
  justify-content: center;
}
