@tailwind base;
@tailwind components;
@tailwind utilities;

.no-scrollbar::-webkit-scrollbar {
  display: none !important;
}

.no-scrollbar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

:root {
  --primaryColor: #111010;
  --secondaryColor: #1a1a1b;
  --paragraphColor: #aeb2b1;
  --innersecondaryColor: #252525;
  --borderColor: #3c403f;
  --headingColor: #fff;

  --font-med: "Arabic Pro Medium";
  --font-reg: "Arabic Pro Reg";
  --font-bold: "Arabic Pro Bold";
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

@font-face {
  font-family: "ds_digital";
  src: url("./assets/font/ds_digital/DS-DIGI.TTF");
}

@font-face {
  font-family: "Arabic Pro Medium";
  src: url("./assets/font/BasisGrotesqueArabicPro-Medium.ttf");
}

@font-face {
  font-family: "Arabic Pro Reg";
  src: url("./assets/font/BasisGrotesqueArabicPro-Regular.ttf");
}

@font-face {
  font-family: "Arabic Pro Bold";
  src: url("./assets/font/BasisGrotesqueArabicPro-Bold.ttf");
}

body {
  background-color: var(--primaryColor);
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

aside {
  background-color: var(--secondaryColor);
  border: 1px solid var(--borderColor);
  border-bottom-right-radius: 15px;
  min-width: 240px;
  width: 240px;
  height: 100vh;
  position: sticky;
  top: 0px;
  overflow: auto;
}

aside::-webkit-scrollbar {
  display: none;
}

aside .total-balance {
  /* background: linear-gradient(148deg, #95ffda 0%, #2fbd8b 100%); */
  background: linear-gradient(144deg, #9BE402 -47.44%, #1FA83C 140.74%);
}

aside .total-balance h1 {
  font-family: var(--font-med);
  line-height: 37.8px;
}

aside .total-balance>p {
  font-family: var(--font-reg);
  line-height: 21px;
}

aside .total-balance button p {
  font-family: var(--font-med);
  line-height: 16.2px;
}

aside .bonus-area {
  background-color: var(--innersecondaryColor);
  min-height: 44px;
}

aside .bonus-area.active {
  background: #3C403F;
}

aside .bonus-area.active h1 {
  color: #6FCF17;
}

aside .bonus-area h1 {
  font-family: var(--font-med);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 135%;
}

aside .bonus-area span {
  font-family: var(--font-bold);
  color: var(--primaryColor);
  font-size: 10px;
  line-height: 135%;
  border-radius: 4px;
  background: var(--Active, #46ca22);
}

aside .refer-button {
  min-height: 44px;
}

aside .first-button-area p,
aside .refer-button p {
  font-family: var(--font-med);
  color: var(--headingColor);
  font-size: 12px;
  line-height: 135%;
}

aside .refer-button p {
  font-family: var(--font-med);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  /* 18.9px */
}

aside .first-button-area>*:first-child {
  background: linear-gradient(to right, #6d2a69, #433150);
}

aside .first-button-area>*:last-child {
  background: linear-gradient(to right, #79642d, #48463c);
}

aside .refer-button {
  /* background: linear-gradient(45deg, #404b39, #111010, #111010); */
  background: linear-gradient(180deg, #9435E2 0%, #3733B9 100%);
}

aside .breaker {
  margin-bottom: 30px;
}

.tabs-area ul {
  background-color: var(--primaryColor);
  padding: 0px;
  height: 43px;
  border: 1px solid var(--borderColor);
}

.tabs-area ul li>.shadow {
  background-color: var(--innersecondaryColor);
  box-shadow: 0px 4px 8px rgba(82.87, 86.63, 95.62, 0.4) inset;
  border-radius: 8px;
  border: 1px #3c403f solid;
}

.tabs-area ul li p {
  color: var(--paragraphColor);
  font-size: 14px;
  font-family: var(--font-med);
  font-weight: 500;
  line-height: 135%;
  /* 18.9px */
  transition: 0.3s ease all;
}

.tabs-area ul li.active p {
  color: var(--headingColor);
}

.tabs-area ul li>* {
  pointer-events: none;
}

/* header area */
header {
  background-color: var(--secondaryColor);
  border-bottom: 1px solid var(--borderColor);
  position: sticky;
  top: 0px;
  z-index: 9999;
}

header .search-box-container>h1 {
  font-family: var(--font-med);
  line-height: 21.6px;
}

header .search-box-container>input {
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.9px;
}

header .tabs-area ul {
  height: 32px;
  width: 204px;
}

header .select-bar>div {
  background-color: var(--innersecondaryColor);
  padding: 5px;
  box-shadow: 0px 4px 8px rgba(82.87, 86.63, 95.62, 0.4) inset;
  border-radius: 8px;
  border: 1px #3c403f solid;
}

header .select-bar>div p {
  color: var(--headingColor);
  font-size: 12px;
  font-family: var(--font-med);
  line-height: 135%;
  /* 16.2px */
}

header .select-bar>div p span {
  color: var(--paragraphColor);
}

header .deposit-button {
  background: linear-gradient(144deg, #9BE402 -47.44%, #1FA83C 140.74%);
  color: var(--primaryColor);
  font-family: var(--font-bold);
  font-size: 12px;
  border: 1px solid transparent;
}

header .border-button {
  color: var(--headingColor);
  background: transparent;
  border-color: #6FCF17;
}

header .icon-btn {
  background-color: var(--innersecondaryColor);
  border: 1px solid var(--borderColor);
}

header .icon-buttons>button {
  background-color: var(--innersecondaryColor);
  border: 1px solid var(--borderColor);
}

header .Avatar {
  border: 1px solid var(--borderColor);
  padding: 2px;
}

.menu-list {
  background-color: #1a1a1b !important;
  border: none;
  padding-left: 0px;
  padding-right: 0px;
}

.menu-list>* {
  color: #8f949d !important;
  border-radius: 0px;
}

.menu-list>*:hover {
  background-color: var(--innersecondaryColor) !important;
  box-shadow: 0px 4px 8px rgba(82.87, 86.63, 95.62, 0.4) inset;
  border-radius: 8px;
}

header .Avatar .arrow-icon {
  width: 10px;
  height: 10px;
  background-color: var(--borderColor);
}

header .Avatar .arrow-icon {
  right: 1px;
  bottom: 0px;
}

.text-neon-green {
  color: #39ff14;
}

/* footer */
footer {
  background-color: var(--secondaryColor);
}

footer .footer-width {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

footer .left-area-footer-why h1,
footer .logo-footer-area h1,
footer .cols-footer-area h1 {
  color: var(--headingColor);
  font-size: 18px;
  font-family: var(--font-med);
  line-height: 21.6px;
  word-wrap: break-word;
}

footer .left-area-footer-why p,
footer .logo-footer-area p,
footer .cols-footer-area p {
  color: var(--paragraphColor);
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}

footer .left-area-footer-why h2 {
  color: white;
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
}

footer .right-area-footer-why h1 {
  color: var(--headingColor);
  font-size: 18px;
  font-family: var(--font-med);
  line-height: 21.6px;
  word-wrap: break-word;
}

footer .right-area-footer-why textarea {
  background-color: var(--primaryColor);
  border: 1px solid var(--borderColor);
  outline: none;

  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}

footer .right-area-footer-why textarea::placeholder {
  color: var(--paragraphColor);
}

footer .right-area-footer-why button {
  background: linear-gradient(180deg, #ffc83e 0%, #ffb700 49%, #d09500 100%);

  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  color: var(--primaryColor);
  font-size: 16px;
  font-family: var(--font-bold);
  font-weight: 700;
  line-height: 21.6px;
  word-wrap: break-word;
}

footer .right-area-footer-why .submit p {
  color: var(--paragraphColor);
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}

footer .right-area-footer-why>p {
  color: var(--paragraphColor);
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}

footer .right-area-footer-why>p a {
  color: #53ebb7;
}

footer .list-area-footer ul .head {
  color: var(--headingColor);
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
  margin-bottom: 29px;
}

footer .list-area-footer ul li:not(.head) a {
  color: var(--paragraphColor);
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
  margin-bottom: 19px;
}

footer .logo-footer-area .right-area p {
  width: 70%;
}

footer .cols-footer-area p span {
  color: var(--headingColor);
}

footer .copyright-area p {
  color: var(--paragraphColor);
  font-size: 16px;
  font-family: var(--font-reg);
  line-height: 19.2px;
  word-wrap: break-word;
}

footer .icons-wrapper h1 {
  color: var(--headingColor);
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
}

footer .icons-wrapper a {
  color: #53ebb7;
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 19.2px;
  word-wrap: break-word;
}

/* LayerUp */
.LayerUp {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0%;
  top: 0px;
  background: rgba(21, 25, 31, 0.8);
  backdrop-filter: blur(4px);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* CheckboxStyle */
.CheckboxStyle label {
  border: 2px solid var(--paragraphColor);
  display: flex;
}

.CheckboxStyleSignUp label {
  width: 18px;
  height: 18px;
  border-color: #53ebb7;
  border-radius: 2px;
  margin-top: 4px;
}

.CheckboxStyleSignUp input:checked+label {
  background-color: #53ebb7;
}

.CheckboxStyle input:checked+label span {
  background: var(--paragraphColor);
}

.scrollOut {
  transition: 1s ease all;
  opacity: 0;
  transform: translateY(100px);
}

p.scrollOut,
h1.scrollOut,
span.scrollOut,
button.scrollOut {
  opacity: 1;
  transform: translateY(0px);
}

.scrollIn {
  opacity: 1;
  transform: translateY(0px);
}

/* Switch Component */
.Switch input {
  display: none;
}

.Switch label {
  width: 32px;
  height: 19px;
  background-color: #2e2e2e;
  border-radius: 99px;
  cursor: pointer;
  padding: 0px 4px;
  transition: 0.3s ease all;
}

.Switch label span {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  transition: 0.3s ease all;
  background-color: #ffffff;
}

.Switch input:checked+label {
  background: linear-gradient(144deg, #9BE402 -47.44%, #1FA83C 140.74%);
}

.Switch input:checked+label span {
  transform: translateX(11px);
}

.margin_2 .dropdown .input-notify-wrapper {
  margin-top: 8px;
}

/* timer popup */
.welcome-ref-popup {
  background: rgba(21, 25, 31, 0.9);
  backdrop-filter: blur(2px);
}

.welcome-ref-inner {
  background: url("./assets/img/welcome-bg.png");
  background-size: cover;
  background-position: center;
}

.timer-inner .timer-box .timer {
  background: url("./assets/img/timer-bag.png");
  background-size: cover;
  background-position: center;
}

.welcome-ref-inner .copy-btn {
  background: linear-gradient(144deg, #9BE402 -47.44%, #1FA83C 140.74%);
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.RightAreaStakeTerms li:not(.heading-li) a {
  color: #47d6a3;
  text-decoration: underline;
}

.RightAreaStakeTerms li:not(.heading-li) span.bold {
  color: #fff;
  font-family: var(--font-bold);
}

.RadioPayment input:checked+label {
  background-color: #fff;
  border-width: 4px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  /* border-top: 4px solid #ffc83e; */
  border-top: 4px solid #6FCF17;
  width: 30px;
  height: 30px;
}

.Toastify__toast-container {
  z-index: 999999 !important;
}

.pagination-table-buttons button p {
  color: #aeb2b1;
  font-size: 12px;
  font-family: "Arabic Pro Medium";
  line-height: 16.2px;
  word-wrap: break-word;
}

.pagination-table-buttons button.active p {
  color: #fff;
}

.pagination-table-buttons button.active svg path {
  fill: #fff;
}

.pagination-table-buttons button.count {
  width: 20px;
  color: #aeb2b1;
  font-size: 10px;
  font-family: "Arabic Pro Medium";
  line-height: 13.5px;
  word-wrap: break-word;
  height: 22px;
}

.pagination-table-buttons button.count.active {
  background-color: #2e2e2e;
  color: #fff;
}

/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  /* Adjust the width of the scrollbar */
  height: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #444;
  /* Color of the scrollbar track */
  margin-top: 2px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar thumb */
  border-radius: 6px;
  /* Rounded corners on the scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar thumb on hover */
}


@keyframes shake {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(3deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-3deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.animate-claim-bonus {
  animation: shake 0.5s ease infinite;
}

.carousel-image {
  max-height: 100%;
  /* Adjust as needed */
  width: auto;
  /* Maintain aspect ratio */
}

