.swiper {
  /* width: 100%; */
  /* text-color: red !important; */
  position: relative;
  color: white;
  height: 100% !important;
  /* margin-left: auto; */
  /* margin-right: auto; */

  /* width: 100% !important; */
  width: 100vw;

  /* overflow: hidden !important; */
}

.swiper-wrapper {
  /* width: 100% !important; */
  height: auto !important;
  /* overflow: hidden !important; */
}

.swiper-slide {
  /* text-align: center;
  font-size: 18px;
  background: #fff; */
  /* height: calc((50% - 30px) / 2) !important; */
  /* height: auto !important; */
  width: 100%;
  /* height: fit-content !important; */
  height: 100%;
  margin-top: 0 !important;
  margin-right: 20 !important;
  /* overflow: hidden !important; */

  /* Center slide text vertically */
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.swiper-pagination {
  position: relative;
  width: 100%;
  height: auto;
  bottom: 0;
}

.swiper-pagination-fraction {
  margin-top: 10px;
  width: 20px;
  height: 20px;
}

.whiteIcon {
  fill: #ffffff;
}
