.popupWrapper {
  width: 100%;
  height: 100vh;
  background: rgba(21, 25, 31, 0.8);
  backdrop-filter: blur(4px);
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-wrapper-bg {
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  position: fixed;
}
.popupWrapper .DepositePopUp {
  background-color: #1a1a1b;
  width: 729px;
  height: 846px;
  z-index: 2;
}
.DepositePopUp .buttons-wrapper {
  border-bottom: 1px solid #3c403f;
}
.DepositePopUp .buttons-wrapper > button {
  color: #FFF;
  height: 50px;
  font-size: 14px;
  font-family: var(--font-med);
  line-height: 16.2px;
  /* word-wrap: break-word; */
  border-radius: 8px;
  border: 1px #3c403f solid;
}
.DepositePopUp .buttons-wrapper > button.active {
  /* background: #252525; */
  background: linear-gradient(144deg, #9BE402 -47.44%, #1FA83C 140.74%);
  color: white;
  border: none;
  font-family: var(--font-reg);
  line-height: 16.2px;
  /* word-wrap: break-word; */
  white-space: nowrap;
  border-bottom: 1px #6FCF17 solid;
}
.DepositePopUp .buttons-wrapper > button.active:hover {
  background: linear-gradient(144deg, #82e402 -47.44%, #146626 140.74%);

}

.Toastify__toast-theme--dark {
  background: #1a1a1b !important;
  box-shadow: 3px 3px 6px #2f2f2f inset;
}
.Toastify__progress-bar-theme--dark {
  background-color: #6FCF17 !important;
}

.DepositePopUp .your-mall > h1 {
  color: white;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.DepositePopUp .your-mall button {
  color: white;
  font-size: 12px;
  font-family: var(--font-med);
  line-height: 16.2px;
  word-wrap: break-word;
  padding: 8px 12px;
  border-radius: 99px;
  background-color: #252525;
  margin-right: 10px;
  border: 1px solid transparent;
}

.DepositePopUp .your-mall button.active {
  border: 1px #6FCF17 solid;
}
.DepositePopUp .input-notify-wrapper {
  height: 50px;
  background-color: #111010;
  border: 1px solid #3c403f;
}

.DepositePopUp .input-notify-wrapper p,
.DepositePopUp .input-notify-wrapper + ul li p {
  color: white;
  font-size: 12px;
  font-family: var(--font-med);
  line-height: 16.2px;
  word-wrap: break-word;
}
.DepositePopUp .input-notify-wrapper h1 {
  color: #aeb2b1;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.DepositePopUp .input-container > label {
  color: white;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.DepositePopUp .content {
  color: #aeb2b1;
  font-size: 12px;
  font-family: var(--font-med);
  line-height: 16.2px;
  word-wrap: break-word;
}
.DepositePopUp .content span {
  color: #fff;
}

.DepositePopUp .qr-container {
  background-color: #252525;
}
.DepositePopUp .qr-container .presentation > h1 {
  color: white;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.DepositePopUp .qr-container .presentation .copy-area {
  height: 48px;
  background-color: #2e2e2e;
  border-radius: 5px;
}
.DepositePopUp .qr-container .presentation .copy-area input {
  color: white;
  font-size: 12px;
  font-family: var(--font-med);
  line-height: 16.2px;
  word-wrap: break-word;
}
.DepositePopUp .qr-container .presentation .copy-area button {
  color: #FFF;
  font-size: 12px;
  font-family: var(--font-bold);
  line-height: 16.2px;
  word-wrap: break-word;
  background: linear-gradient(144deg, #9BE402 -47.44%, #1FA83C 140.74%);
  border-radius: 4px;
  height: 28px;
  width: 104px;
}

.DepositePopUp .warning {
  color: white;
  font-size: 12px;
  font-family: var(--font-reg);
  word-wrap: break-word;
  height: 50px;
  background: linear-gradient(
    180deg,
    rgba(61.62, 255, 187.32, 0.1) 0%,
    rgba(0, 255, 165.75, 0.1) 49%,
    rgba(0, 208.25, 135.36, 0.1) 100%
  );
}
.DepositePopUp .warning span {
  color: #6FCF17;
}

.DepositePopUp .dropdown-head {
  background-color: #252525;
  border: 1px solid #3c403f;
}
.DepositePopUp .paydropdown {
  background-color: #2e3543;
  border: 1px solid #323743;
}
.DepositePopUp .DepositeCurrencyDropdown {
  background-color: #15191f;
  border: 1px solid #323743;
  border-top: 0px;
}
.DepositePopUp .DepositeCurrencyDropdown p {
  color: white;
  font-size: 12px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
}
.DepositePopUp .DepositeCurrencyDropdown h1 {
  color: #aeb2b1;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.DepositePopUp .agreement p {
  color: white;
  font-size: 12px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
}
.DepositePopUp .agreement label {
  border: 1px solid #fff;
  cursor: pointer;
}
.DepositePopUp .agreement label svg {
  display: none;
}
.DepositePopUp .agreement input {
  display: none;
}
.DepositePopUp .agreement input:checked + label svg {
  display: block;
}
.DepositePopUp .moon-pay-btn {
  height: 50px;
  color: #6FCF17;
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 16.2px;

  word-wrap: break-word;
  background: linear-gradient(
    180deg,
    rgba(61.62, 255, 187.32, 0.1) 0%,
    rgba(0, 255, 165.75, 0.1) 49%,
    rgba(0, 208.25, 135.36, 0.1) 100%
  );
  border-radius: 8px;
  border: 1px #6FCF17 solid;
}
.DepositePopUp .moon-pay-btn:hover {
  background: linear-gradient(
    180deg,
    rgba(40, 251, 177, 0.1) 0%,
    rgba(2, 188, 123, 0.1) 49%,
    rgba(0, 129, 84, 0.1) 100%
  );
}
.DepositePopUp .moon-pay-btn-pure {
  color: #FFF;
  font-size: 16px;
  font-family: var(--font-med);
  background: linear-gradient(144deg, #9BE402 -47.44%, #1FA83C 140.74%);
}
.DepositePopUp .moon-pay-btn-pure:hover {
  background: linear-gradient(144deg, #82e402 -47.44%, #146626 140.74%);
}
.DepositePopUp .details-wrapper {
  background-color: #252525;
}
.DepositePopUp .details-wrapper p {
  color: white;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.DepositePopUp .details-wrapper h1 {
  color: #aeb2b1;
  font-size: 12px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.DepositePopUp .details-wrapper .yellow {
  color: #6FCF17;
}

.DepositePopUp .input-notify-wrapper + ul {
  background-color: #111010 !important;
}
.DepositePopUp .input-notify-wrapper + ul li {
  border-bottom: 1px solid #3c403f !important;
}
@media screen and (max-height: 846px) {
  .popupWrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .popupWrapper .DepositePopUp {
    height: 90vh;
    overflow: auto;
  }
  .popupWrapper .DepositePopUp::-webkit-scrollbar {
    display: none;
  }
}
