/* input[type="radio"]:checked + label {
    color: green;
} */

input[type="radio"]#option1 {
  accent-color: #6fcf17;
  background-color: #111010;
}

input[type="radio"]#option2 {
  accent-color: #6fcf17;
  background-color: #111010;
}

.msl {
  /* border: transparent !important; */
  border: 1px solid #464646 !important;
}
.msl-vars {
  --input-width: 100% !important;
  /* background-color: #6fcf17; */
}

.msl-active {
  background: #252525 !important;
}

.msl-active-up,
.msl-active ~ .msl-options {
  background: #252525 !important;
}


.msl-grp-title,
.msl-options .msl-option {
  color: white;
}
.msl-options .msl-option:hover {
  background: #4a4a4a !important;
}

.msl-option-active {
  background: #4a4a4a !important;
}

.msl-active-up ~ .msl-options {
  background: #252525 !important;
}
.msl-input {
  justify-self: end !important;
  place-self: end !important;
  margin-left: auto !important;
  color: white !important;
  padding: 0.5rem !important;
}

.msl-single-value,
.msl-chip {
  /* background: #4a4a4a !important; */
  background: #505050 !important;
  border: 1px solid #464646 !important;
  color: white !important;
  padding: 0.5rem !important;
  border-radius: 6px !important;
}

.msl-chip-delete {
  margin-left: 1rem !important;
  background: transparent !important;
  color: #ffffff !important;
}

.msl-arrow-btn:hover,
.msl-clear-btn:hover {
  background: #6fcf17 !important;
}

.msl-arrow-btn,
.msl-clear-btn {
  color: white;
}

/* Hide the default checkbox */
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

/* Custom checkbox when checked */

/* Style the default checkmark */
input[type="checkbox"]::before {
  content: "\2714"; /* Unicode for checkmark */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px; /* Adjust size as needed */
  color: white; /* White color for the checkmark */
  visibility: hidden;
}

/* Show the checkmark when checkbox is checked */
input[type="checkbox"]:checked::before {
  visibility: visible;
}

.styled-radio {
  width: 20px;
  height: 20px;
  /* Use custom background color */
  background-color: #252525;
  /* Use custom border */
  border: 2px solid grey;
  /* Use custom border-radius for rounded corners */
  border-radius: 50%;
  /* Use custom margin for spacing */
  margin-right: 10px;
}

/* Style for the modal */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal content */
.modal-content {
  background-color: #252525;
  border: 1px solid #3c403f;
  width: 80%;
  max-width: 649px; /* Adjust maximum width as needed */
  color: white;
}

/* Close button */
.close {
  /* display: inline; */
  color: #aaa;
  font-size: 28px;
  font-weight: lighter;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: white;
  text-decoration: none;
}

#time {
  background-color: transparent; /* Change background color of dropdown menu */
}

#time option {
  background-color: #252525; /* Change background color of options */
  color: white; /* Change text color of options */
  border-bottom: 8px;
}

#time option:hover {
  background-color: #505050; /* Change background color of option on hover */
  color: black; /* Change text color of option on hover */
}



@media only screen and (max-width: 600px) {
  .modal-content {
    width: 90%;
    max-width: none; /* Resetting max-width for phones */
  }
}