.user-profile {
  width: 515px;
  height: 90%;
  overflow: auto;
  background-color: #1a1a1b;
}
.user-profile::-webkit-scrollbar {
  display: none;
}
.user-profile .top > h1 {
  color: var(--headingColor);
  font-size: 18px;
  font-family: var(--font-bold);
  line-height: 21.6px;
  word-wrap: break-word;
}
.user-profile .master-medal-list {
  background-color: #252525;
  padding: 12px;
  border-radius: 5px;
}
.user-profile .master-medal-list li {
  color: #959699;
  font-size: 12px;
  font-family: var(--font-reg);
  line-height: 16.2px;
  word-wrap: break-word;
}
.user-profile .master-medal-list li span {
  color: #5df9c2;
}

.user-profile .heading-medal {
  color: white;
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
}
.user-profile .heading-medal.border-custom {
  border-bottom: 1px solid #323743;
}

.heading-medal-grid .box-coin {
  background-color: #252525;
  height: 184px;
  border-radius: 5px;
}
.heading-medal-grid .box-coin h1 {
  color: white;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.heading-medal-grid .box-coin .badge {
  height: 32px;
  width: 67px;
  border-radius: 8px;
  background-color: #2e2e2e;
  border: 1px solid #3c403f;
}
.heading-medal-grid .box-coin .badge p {
  color: #5df9c2;
  font-size: 12px;
  font-family: var(--font-bold);

  word-wrap: break-word;
}
.user-profile .box-wrapper-master-medal .box {
  background-color: #252525;
  height: 96px;
  border-radius: 5px;
}
.user-profile .box-wrapper-master-medal .box h1 {
  color: white;
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
}

.user-profile .percentage-master-medal {
  background-color: #252525;
  border-radius: 5px;
}
.user-profile .percentage-master-medal div {
  background-color: #3c403f;
  height: 8px;
  width: 100%;
  border-radius: 5px;
}
.user-profile .percentage-master-medal span {
  height: 100%;
  border-radius: 5px;
  display: block;
  background: linear-gradient(136.6deg, #5df9c2 9.33%, #3ac192 88.1%);
}
.user-profile .like-wrapper {
  background-color: #252525;
  border: 1px solid #3c403f;
}
.user-profile .like-wrapper p {
  color: var(--headingColor);
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 17.5px;
  word-wrap: break-word;
}

.user-profile .main-profile h1 {
  color: var(--headingColor);
  font-size: 23px;
  font-family: var(--font-med);
  line-height: 26.45px;
  word-wrap: break-word;
}
.user-profile .main-profile p {
  color: var(--paragraphColor);
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}

.user-profile .metal-box {
  background-color: var(--innersecondaryColor);
}

.user-profile .metal-box h1 {
  color: var(--headingColor);
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
}
.user-profile .metal-box p {
  color: var(--headingColor);
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}

.user-profile .metal-box .row p {
  color: var(--paragraphColor);
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.user-profile .metal-box a {
  color: #6FCF17;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}

.user-profile .boxes-wrapper > div {
  height: 95px;
  background-color: #2e2e2e;
}
.user-profile .boxes-wrapper > div h1 {
  color: var(--headingColor);
  font-size: 14px;
  font-family: var(--font-reg);
  word-wrap: break-word;
}
.user-profile .boxes-wrapper > div p {
  color: var(--headingColor);
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
}

/* edit profile */
.user-profile .head-edit {
  background-color: var(--innersecondaryColor);
}
.user-profile .head-edit h1 {
  color: white;
  font-size: 18px;
  font-family: var(--font-bold);
  line-height: 21.6px;
  word-wrap: break-word;
}

.user-profile .edit-profile-main label {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  border: 1px rgba(255, 255, 255, 0.3) solid;
  backdrop-filter: blur(14px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 135px;
  height: 42px;
  display: inline-flex;
  color: white;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
  transform: translateY(-15px);
}

.user-profile .input-wrapper label {
  color: #696c76;
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
}
.user-profile .input-wrapper input {
  border-radius: 5px;
  height: 54px;
  background-color: #111010;
  color: #fff;
  border: none;
  outline: none;
  color: white;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.user-profile .input-wrapper p {
  color: #959699;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}

.user-profile .input-wrapper button {
  color: #15191f;
  font-size: 16px;
  height: 50px;
  font-family: var(--font-bold);
  line-height: 21.6px;
  word-wrap: break-word;
  background: linear-gradient(136.6deg, #5df9c2 9.33%, #3ac192 88.1%);
}

.user-profile .games-body-details h1 {
  color: #959699;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}

.user-profile .top-px > h1 {
  color: var(--headingColor);
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
}

.user-profile .link-wrapper {
  height: 38px;
  width: 184px;
  background-color: #111010;
  padding: 0px 10px;
}
.user-profile .link-wrapper p {
  color: #959699;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}

.user-profile .profile-px > h1 {
  color: var(--headingColor);
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
}
