.wallet-content-body-area .balance {
  border-bottom: 1px solid #52565e;
}
.wallet-content-body-area .balance h1 {
  color: white;
  font-size: 14px;
  font-family: var(--font-med);
  line-height: 18.2px;
  word-wrap: break-word;
}
.wallet-content-body-area .balance p {
  color: #fff;
  font-size: 14px;
  font-family: var(--font-med);
  line-height: 18.9px;
  word-wrap: break-word;
}

.wallet-content-body-area .hide-balance > h1 {
  color: #aeb2b1;
  font-size: 12px;
  font-family: var(--font-med);
  line-height: 16.2px;
  word-wrap: break-word;
}

.wallet-content-body-area .search-wrapper {
  width: 108px;
  background-color: #111010;
  border: 1px solid #3C403F;
}
.wallet-content-body-area .search-wrapper input {
  color: #aeb2b1;
}

.wallet-content-body-area .currency-balance > h1 {
  color: white;
  font-size: 12px;
  font-family: var(--font-med);
  line-height: 16.2px;
  word-wrap: break-word;
}

/* .currency-balance .row {
  background-color: #252525;
  padding: 0px 10px;
  border-radius: 5px;
} */
.currency-balance .row h1 {
  color: white;
  font-size: 12px;
  font-family: var(--font-med);
  line-height: 16.2px;
  word-wrap: break-word;
}
.currency-balance .row p {
  color: #aeb2b1;
  font-size: 10px;
  font-family: var(--font-reg);
  line-height: 13.5px;
  word-wrap: break-word;
}
.currency-balance .row button {
  /* background: linear-gradient(
    180deg,
    rgba(61.62, 255, 187.32, 0.1) 0%,
    rgba(0, 255, 165.75, 0.1) 49%,
    rgba(0, 208.25, 135.36, 0.1) 100%
  ); */
  background: linear-gradient(144deg, #9BE402 -47.44%, #1FA83C 140.74%);
  border-radius: 4px;
  color: #FFF;
  font-size: 12px;
  height: 24px;
  width: 68px;
  font-family: var(--font-med);
  line-height: 16.2px;
  word-wrap: break-word;
}
.currency-balance .row button:hover {
  background: linear-gradient(144deg, #82e402 -47.44%, #146626 140.74%);
}
.DepositePopUp .input-notify-wrapper input {
  color: #aeb2b1;
  font-size: 12px;
  font-family: var(--font-med);
  line-height: 16.2px;
  word-wrap: break-word;
}
.wallet-content-body-area .warning {
  height: unset;
}
.wallet-content-body-area .warning p {
  color: #aeb2b1;
  width: 410px;
  font-size: 12px;

  font-weight: 400;
  word-wrap: break-word;
}

.wallet-content-body-area .min-label {
  color: #aeb2b1;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;

  word-wrap: break-word;
}
.wallet-content-body-area .tags-wrapper {
  background-color: #252525;
  border-radius: 4px;
}
.wallet-content-body-area .tags-wrapper button {
  padding: 0px 8px;
  height: 30px;
  color: #aeb2b1;
  border-right: 2px solid #15191f;
  font-size: 10px;
}

.wallet-content-body-area .tags-wrapper button.active {
  background-color: #6FCF17;
  color: #000;
}

.wallet-content-body-area .moon-pay-btn.outline-btn {
  background: transparent;
  color: #fff;
  font-size: 14px;
}

.wallet-content-body-area .average-percentage h1 {
  color: white;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.wallet-content-body-area .average-percentage h1 span {
  color: #6FCF17;
}

.wallet-content-body-area .average-percentage p {
  color: #aeb2b1;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}

.wallet-content-body-area .box-total h1 {
  color: #aeb2b1;
  font-size: 14px;
  font-family: var(--font-med);
  line-height: 18.2px;
  word-wrap: break-word;
}
.wallet-content-body-area .buttons-wrapper .box-total:first-child {
  border-right: 1px solid #464646;
}
.wallet-content-body-area .box-total p {
  color: white;
  font-size: 23px;
  font-family: var(--font-med);
  line-height: 26.45px;
  word-wrap: break-word;
}

.wallet-content-body-area .option-three .upload-box {
  height: 221px;
  width: 220px;
  background-color: #111010;
}
.wallet-content-body-area .option-three .upload-box button {
  color: #aeb2b1;
  font-size: 12px;
  font-family: var(--font-reg);
  line-height: 15.6px;
  word-wrap: break-word;
  background: #252525;
}

.wallet-content-body-area .bonus-area {
  height: 50px;
  background-color: #252525;
  border: 1px solid #3c403f;
  cursor: pointer;
}
.wallet-content-body-area .bonus-area h1 {
  color: #aeb2b1;
  font-size: 14px;
  font-family: var(--font-med);
  line-height: 18.2px;
  word-wrap: break-word;
}

.wallet-content-body-area .no-data-available {
  color: #aeb2b1;
  font-size: 14px;
  font-family: var(--font-med);
  line-height: 18.2px;
  word-wrap: break-word;
}

.progress-bar-like {
  appearance: none;
  border-radius: 50px; 
  height: 10px;
  align-self: center;
}

.progress-bar-like::-webkit-progress-bar {
  background-color: #3A3A3A;
}

.progress-bar-like::-webkit-progress-value {
  background-color: #6FCF17;
}

.progress-bar-like::-moz-progress-bar {
  background-color: #6FCF17;
}



.progress-bar-dislike {
  appearance: none;
  border-radius: 50px; 
  height: 10px; 
  align-self: center;
}

.progress-bar-dislike::-webkit-progress-bar {
  background-color: #3A3A3A; 
}

.progress-bar-dislike::-webkit-progress-value {
  background-color: #F6465D;
}

.progress-bar-dislike::-moz-progress-bar {
  background-color: #F6465D;
}


