.settings-area {
  background-color: #1a1a1b;
  height: 100%;
  width: 100%;
}
.settings-head {
  border-bottom: 1px solid #52565e;
}
.settings-head > h1 {
  color: white;
  font-size: 18px;
  font-family: var(--font-bold);
  line-height: 21.6px;
  word-wrap: break-word;
}
.settings-content-area {
  min-height: 840px;
}
/* .settings-options-area {
  width: 316px;
} */
.settings-content-area::after {
  width: 1px;
  height: 100%;
  background-color: #52565e;
  position: absolute;
  left: 194px;
  content: "";
  top: 0px;
}
.settings-options-area a {
  height: 43px;
  padding: 0px 20px;
  color: #959699;
  font-size: 14px;
  font-family: var(--font-med);
  line-height: 18.9px;
  word-wrap: break-word;
}
.settings-options-area a.active {
  background-color: #252525;
  color: #fff;
}
.settings-options-area a.active svg path {
  fill: #fff;
}
.settings-content-area .switch-row {
  border-bottom: 1px solid #323743;
}
.settings-content-area .switch-row h1 {
  color: #959699;
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
}

.gtranslate_wrapper {
  position: relative !important;
  left: 40% !important;
  transform: translateX(-50%);
  top: 0% !important;
  width: 100% !important;
  /* margin-top: 3rem; */
}
/* .gtranslate_wrapper {
  position: absolute !important;
  left: 50% !important;
  transform: translateX(-50%);
  top: 0% !important;
  width: 100% !important;
  width: 1340px !important;
} */
.gt_switcher_wrapper {
  position: relative;
  top: 0px !important;
  left: 5px !important;
  right: unset !important;
}
.gt_container--hich1m .gt_switcher .gt_option{
  background: #111010 !important;
}
.gt_container--hich1m .gt_switcher {
  background: transparent !important;
  width: 180px !important;
  border-radius: 8px;
}
.gt_container--hich1m .gt_switcher .gt_selected {
  background: #111010 !important;
  border-radius: 8px;
  border: 1px solid #3c403f;
}
.gt_container--hich1m .gt_switcher a {
  font-size: 16px !important;
  font-family: var(--font-arabic-med) !important;
  color: #fff !important;
  border-color: transparent !important;
}
.gt_container--hich1m .gt_switcher a:hover {
  color: #111010 !important;
}


.settings-content-area .DropdownSettingsHead {
  height: 40px;
  width: 176px;
  background-color: #111010;
  border: 1px solid #3c403f;
}
.settings-content-area .DropdownSettingsHead + ul li {
  height: 40px;
  background-color: #111010;
  border: 1px solid #3c403f;
}
.settings-content-area .DropdownSettingsHead + ul li h1 {
  color: #fff;
}
.settings-content-area .DropdownSettingsHead > h1 {
  color: white;
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
}

.settings-content-area .privacy-content > h1 {
  color: white;
  font-size: 16px;
  line-height: 20px;
  word-wrap: break-word;
}
.settings-content-area .privacy-content > p {
  color: #959699;
  font-size: 14px;
  width: 496px;
  line-height: 18.2px;
  word-wrap: break-word;
}

.settings-content-area .form-card-mail > h1 {
  color: white;
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
}
.settings-content-area .form-card-mail > p {
  color: #959699;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
  width: 90%;
}
.settings-content-area .form-card-mail:first-child::after {
  width: 1px;
  height: 100%;
  background-color: #52565e;
  position: absolute;
  content: "";
  top: 0px;
  right: -12px;
}
.settings-content-area .form-card-mail .input-wrapper label {
  color: white;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.settings-content-area .form-card-mail .input-wrapper .input-container {
  background-color: #111010;
  border: 1px solid #3c403f;
  height: 50px;
}
.settings-content-area .form-card-mail .input-wrapper .input-container button {
  background-color: #252525;
  min-width: 70px;
  color: white;
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
}
.settings-content-area
  .form-card-mail
  .input-wrapper
  .input-container
  button
  ul {
  background-color: #2e3543;
}
.settings-content-area
  .form-card-mail
  .input-wrapper
  .input-container
  button
  ul
  li {
  padding: 0px;
}
.settings-content-area .form-card-mail .input-wrapper input {
  color: #959699;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.settings-content-area .form-card-mail .active-2 > button,
.settings-content-area .form-card-mail > button {
  color: #FFF;
  font-size: 16px;
  font-family: var(--font-bold);
  line-height: 21.6px;
  word-wrap: break-word;
  height: 50px;
  background: linear-gradient(144deg, #9BE402 -47.44%, #1FA83C 140.74%);
}
.settings-content-area .form-card-mail .active-2 > button:hover,
.settings-content-area .form-card-mail > button:hover {
  background: linear-gradient(144deg, #82e402 -47.44%, #146626 140.74%);
}
.settings-content-area
  .form-card-mail-security
  .input-wrapper
  .input-container
  button {
  min-width: 50px;
}
.settings-content-body-area > .heading {
  color: white;
  font-size: 14px;
  font-family: var(--font-med);
  line-height: 18.2px;
  word-wrap: break-word;
}
.settings-content-body-area > table {
  border-collapse: collapse;
}
.settings-content-body-area > table thead th {
  color: #959699;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  text-align: left;
  word-wrap: break-word;
}
.settings-content-body-area > table tbody td {
  height: 52px;
  background-color: #252525;
  border-bottom: 10px solid #1a1a1b;

  color: white;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.settings-content-body-area > table th {
  height: 42px;
}
.settings-content-body-area > table tbody td:first-child,
.settings-content-body-area > table thead th:first-child {
  padding-left: 16px;
}
.settings-content-body-area > table tbody td:last-child,
.settings-content-body-area > table thead th:last-child {
  padding-right: 16px;
}

.settings-content-body-area .verify-settings > h1 {
  color: white;
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
}
.settings-content-body-area .verify-settings > p {
  color: #959699;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}

.verify-settings-wrapper .DropdownSettingsHead {
  height: 50px;
  width: 100%;
}
.verify-settings-wrapper input {
  outline: none;
  width: 100%;
}

.verify-settings-wrapper .button-step {
  color: #959699;
  font-size: 14px;
  font-family: var(--font-reg);
  background: #252525;
  border-radius: 8px;
  height: 42px;
  border: 1px #3c403f solid;
  line-height: 18.2px;
  word-wrap: break-word;
  position: relative;
}
.verify-settings-wrapper .button-step::after {
  width: 100%;
  height: 1px;
  background-color: #52565d;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  content: "";
}
.verify-settings-wrapper .button-step:last-child::after {
  display: none;
}
.verify-settings-wrapper .button-step.active {
  background: linear-gradient(144deg, #9BE402 -47.44%, #1FA83C 140.74%);
  color: #15191f;
  font-size: 14px;
  font-family: var(--font-bold);
  line-height: 18.2px;
  word-wrap: break-word;
}
.verify-settings-wrapper input[type="date"]::-webkit-inner-spin-button,
.verify-settings-wrapper input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.verify-settings-wrapper .active-2 > h2 {
  color: white;
  text-align: center;
  font-size: 12px;
  font-family: var(--font-reg);
  line-height: 16.2px;
  word-wrap: break-word;
}
.verify-settings-wrapper .active-2 > p {
  color: #959699;
  font-size: 12px;
  font-family: var(--font-reg);
  line-height: 16.2px;
  word-wrap: break-word;
}
.verify-settings-wrapper .active-2 .buttons-wrapper button {
  color: #959699;
  font-size: 12px;
  font-family: var(--font-med);
  line-height: 16.2px;
  word-wrap: break-word;
  border: 1px #3c403f solid;
}
.verify-settings-wrapper .active-2 .buttons-wrapper button.active {
  background-color: #252525;
  color: #fff;
}
.verify-settings-wrapper .active-2 .card-wrapper > p {
  color: #959699;
  font-size: 10px;
  font-family: var(--font-reg);
  line-height: 14px;
  word-wrap: break-word;
}
.verify-settings-wrapper .active-2 .card-wrapper h1 {
  color: white;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.verify-settings-wrapper .active-2 .card-wrapper label {
  color: #6FCF17;
  font-size: 12px;
  font-family: var(--font-bold);
  background: linear-gradient(144deg, rgba(155, 228, 2, 0.20) -47.44%, rgba(31, 168, 60, 0.20) 140.74%);
  border-radius: 8px;
  border: 1px #6FCF17 solid;
  line-height: 16.2px;
  word-wrap: break-word;
}

.verify-settings-wrapper .active-2 .profile-wrapper h1 {
  color: white;
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 18.2px;
  word-wrap: break-word;
}
.verify-settings-wrapper .active-2 .profile-wrapper p {
  color: #959699;
  font-size: 10px;
  font-family: var(--font-reg);
  line-height: 14px;
  word-wrap: break-word;
}
.verify-settings-wrapper .active-2 .profile-wrapper label {
  color: #6FCF17;
  font-size: 12px;
  font-family: var(--font-bold);
  background: linear-gradient(144deg, rgba(155, 228, 2, 0.20) -47.44%, rgba(31, 168, 60, 0.20) 140.74%);
  border-radius: 8px;
  border: 1px #6FCF17 solid;
  line-height: 16.2px;
  word-wrap: break-word;
  width: 149px;
}
.verify-settings-wrapper .active-4 {
  padding-top: 10rem;
}
.verify-settings-wrapper .active-4 > h1 {
  color: white;
  font-size: 16px;
  font-family: var(--font-med);
  line-height: 20px;
  word-wrap: break-word;
}

.verify-settings-wrapper .active-4 > p {
  color: #959699;
  font-size: 12px;
  font-family: var(--font-reg);
  line-height: 16.2px;
  word-wrap: break-word;
}
.react-tel-input .country-list li {
 color: #ffffff;
}
.react-tel-input .country-list li.highlight,
.react-tel-input .country-list li:hover {
  background-color: #252525 !important;
}

/*===== TEXT GLOW =====*/
@keyframes glowing {
  0% {
    text-shadow: 0 0 5px #ceff647d, 0 0 7px #ceff647d;
  }
  50% {
    text-shadow: 0 0 15px #ceff647d, 0 0 15px #ceff647d;
  }
  100% {
    text-shadow: 0 0 5px #ceff647d, 0 0 7px #ceff647d;
  }
}

.glow-text {
  display: inline-block;
  animation: glowing 2s infinite;
}