.affiliate-program {
  background: url("../../img/affiliate/bg-affiliate-program.png");
  background-size: cover;
  background-position: center;
}

.home .body-left-area .welcome-area {
  background: url("../../img/affiliate/commision.png");
  background-size: cover;
  background-position: center;
}

/* .home .body-left-area .banner-live {
  background: url("../../img/affiliate/gamebox-banner.svg"), #010313;
  background-position: right;
  background-repeat: no-repeat;
}
@media screen and (max-width: 500px) {
  .home .body-left-area .banner-live {
    background: url("../../img/gamebox-banner-mbl.svg");
    background-size: cover;
    background-position: top;
  }
} */
